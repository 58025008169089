.input-ext {
  &__container {
    position: relative;
    text-align: left;

    .ant-input-password-large.input-ext:not(textarea) {
      height: 48px !important;
    }

    &--error .input-ext {
      border-color: #ffa39e !important;
    }

    &--error .input-ext:hover {
      border-color: #f5222d !important;
    }

    &--error .input-ext:focus,
    &--error .ant-input-affix-wrapper:focus,
    &--error .ant-input-affix-wrapper-focused {
      border-color: #f5222d !important;
      box-shadow: 0 0 0 2px rgba(#f5222d, 0.2) !important;
    }
  }

  &__top {
    margin-bottom: 8px;
    display: inline-block;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
  }

  &__required {
    position: absolute;
    left: 100%;
    bottom: 10px;
    margin-left: 5px;
    color: #ea5455;
  }

  &__error {
    color: var(--error-color);
  }

  &.ant-input,
  &.ant-input-affix-wrapper {
    border-radius: 4px;
  }

  &.ant-input-password-large {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.43;
  }

  &.ant-input-affix-wrapper {
    padding-right: 16px !important;
  }

  .ant-input-password-icon {
    font-size: 16px;
  }
}
