.avro-element-container {
  border-color: black;
  border-width: 1px;
  border-radius: 8px;
  border-style: none;
  margin: 3px;
  margin-left: 8px;
  width: 500px;
}

.avro-inner {
  margin-left: 20px;
}

.avro-button-container {
  display: flex;
  .avro-button {
    margin-top: 3px;
  }
}

.avro-type-selector {
  width: 100%;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  width: 8px;
  height: 8px;
  transition: 0.3s ease-in-out;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 8px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.avro-select-before {
  width: auto;
  padding-top: 6px;
}

.avro-switch-container {
  width: 110px;

  button {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .ant-switch-handle::before {
    border-radius: 0;
    height: 32px;
  }
}

.avro-accordion {
  .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
    height: 30px;
    padding: 7px !important;
  }
}