.input-output-data-script {
  padding: 30px 24px;

  &__tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
  }
}
