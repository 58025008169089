@import '../../../../mixins.scss';

.external-service-access {
  position: relative;
  margin: 16px 0;
  @include card-styles();

  &__content {
    position: relative;
    overflow: hidden;

    .ant-table-thead {
      position: sticky;
      top: 0;
      background-color: #fafafa;
      z-index: 10 !important;
    }
  }
}
