.end {
  padding: 24px;

  .end {
    &__top-content {
      margin-bottom: 30px;
      display: flex;
    }

    &__field {
      flex-basis: 100%;

      &--title {
        flex-basis: 50%;
      }

      &--code {
        margin-left: 40px;
        flex-basis: 30%;
      }
    }
  }

  .data-table {
    height: 400px;
  }

  .table-attr-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_value {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_tooltip {
      cursor: pointer;
    }

    .button_icon {
      margin: -10px 0;
    }
  }
}
