.checkbox-list {
  $size: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .checkbox-item-template {
    display: inline-flex;
    width: 100%;
    border: 1px solid #ebeaed;
    border-radius: 4px;
    background-color: var(--second-bg-color);
    padding: 24px 24px 24px 20px;

    &__checkbox {
      padding-top: 20px;
    }
  }

  &-base {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      text-align: start;
      width: 100%;
      .ant-checkbox-group-item {
        padding-bottom: 20px;
      }
    }
    &__checked-all {
      padding-bottom: 17px;
      font-weight: 500;
    }
  }
}
