.header {
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  background-color: #202124;

  $second: #{&}--second;

  &__logo {
    padding: 10px 30px;
    display: flex;

    &-image {
      display: block;
    }
  }

  &__btn-group {
    display: flex;
    margin-left: auto;

    .group-item {
      display: flex;
      align-items: center;
      background-color: #202124;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #3e3f44;
      }

      svg {
        color: #fff;
      }
    }

    #{$second} & {
      .group-item {
        background-color: #fff;

        &:hover {
          background-color: #fff;
        }

        .user__name,
        .language,
        svg {
          color: #2e2c34;
        }
      }
    }
  }

  &__question {
    .question {
      padding: 0 15px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  &__notifications {
    .notifications {
      padding: 0 15px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .ant-badge-count {
        box-shadow: none;
        font-weight: 500;
        font-size: 9px;
        color: #f1f3f4;
        transform: translate(2px, -2px);
      }
    }
  }

  &__settings {
    .settings {
      padding: 0 14px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      &__menu {
        display: flex;
        flex-direction: column;
      }

      &__icon {
        display: block;
        width: 22px;
      }
    }
  }

  &__user {
    padding: 0 30px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .user {
      display: flex;
      align-items: center;

      &__text {
        margin: 0 14px 0 0;
        line-height: 1.5;
        text-align: right;
      }

      &__name {
        margin: 0;
        font-size: 14px;
        color: #ffffff;
      }

      &__role {
        margin: 0;
        font-size: 12px;
        color: #b9b9c3;
      }

      .ant-scroll-number.ant-badge-dot {
        top: initial;
        right: 0;
        bottom: 0;
        width: 8px;
        min-width: 8px;
        height: 8px;
        box-shadow: 0 0 0 2px #fff;
        transform: translate(-2px, -2px);
      }
    }
  }

  &--second {
    background-color: #fff;
  }
}
