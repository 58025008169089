.calc-scorecard {
  padding: 20px 24px 6px;

  .calc-scorecard {
    &__top-content {
      display: flex;
    }
    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 58%;
        margin-bottom: 24px;
      }
      &--code {
        flex-basis: 36%;
        margin-left: 47px;
      }
      &--description {
        margin-bottom: 30px;
      }
    }
    &__tables:not(:last-child) {
      margin-bottom: 30px;
    }
    &__btns-group-left {
      display: flex;
      margin-bottom: 30px;
      justify-content: flex-start;
      .button--usual-text-style:not(:last-child) {
        margin-right: 20px;
      }
    }

    &__table {
      &_params {
        display: flex;
        flex-direction: row;
        width: 75%;
        margin-bottom: 15px;
      }
      &-title {
        text-align: left;
      }
      &_params_names {
        display: flex;
        flex-direction: row;
        width: 100%;
        & > .simple-table-wrapper {
          width: 90%;
        }

        & .data-table {
          background-color: #fff;
          box-shadow: inset 0 0 0 1px #000;
          overflow-y: scroll;
          height: 300px;
          width: 90%;
        }
      }

      .data-table {
        width: 100%;
        &__edit-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__input-with-edit {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-shrink: 0;
        }
        &__input-before-edit-title {
          margin: 0 0 0 30px !important;
        }
        &__input-before-edit-btn {
          margin: -12px 30px !important;
          flex-basis: 100%;
          flex-shrink: 1;
        }

        &_btns {
          width: 40px;
          padding: 0 10px 0 4px;

          img {
            width: 30px;
            height: 30px;
          }

          button {
            margin-bottom: 5px;
          }
        }
      }

      .select-ext__container,
      .input-ext__container,
      .button_icon {
        margin: -12px 0;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }

    .btn-group-bottom {
      margin-top: 40px;
    }
  }
}

button {
  cursor: pointer;
}
