.custom-code-modal {
  padding: 20px 24px 24px 24px;

  .custom-code-modal {
    &__top-content {
      margin-bottom: 24px;
      display: flex;
    }
    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 39%;
      }
      &--code {
        flex-basis: 19%;
        margin-left: 47px;
      }
      &--select-script {
        flex-basis: 19%;
      }
      &--select-version {
        flex-basis: 39%;
        margin-left: 47px;
      }
    }
  }

  &__script {
    resize: none;
    width: 100%;
    margin-top: 24px;

    &-title {
      font-size: 14px;
      line-height: 40px;
      color: #000000;
    }
  }
  &__table-input {
    margin: -10px 0;
  }

  &__tables {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}
