.ant-picker-content .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-content .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-content .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #0048b2;
    color: white;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
    width: initial;
}
// .ant-picker-body {
//     margin-left: 20px;
//     margin-right: 20px;
// }

.ant-picker-content .ant-picker-cell .ant-picker-cell-inner {
    height: 41px;
    width: 41px;
    border-radius: 4px;
    line-height: 40px;
}
.ant-picker-content
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 4px;
}
.ant-picker-content
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 4px;
}
.ant-picker-content thead {
    border-bottom: 1px solid #f0f0f0;
    background-color: #fbfafc;
}
.ant-picker-header {
    height: 44px;
    border: none;
    background-color: #fbfafc;
    align-items: center;
}
.ant-picker-header-view {
    margin: auto;
}
.ant-picker-date-panel .ant-picker-content {
    width: initial;
    display: block;
}

.ant-picker-date-panel {
    border-left: 1px solid #f0f0f0;
}

.ant-picker-panels .ant-picker-date-panel .ant-picker-body {
    padding: initial;
}

.ant-picker-content .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    content: "";
    position: absolute;
    left: 18px;
    top: 37px;
    width: 5px;
    height: 5px;
    border-radius: 6px;
    background-color: #0048b2;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-selected .ant-picker-cell-inner::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-selected .ant-picker-cell-inner::before,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-today.ant-picker-cell-selected:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner::before{
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    z-index: 3;
    top: 70%;
    left: 23%;
    border-radius: 50%;
    box-shadow: 5px 1px 0 0 white, 13px 1px 0 0 white;
    transition: none;
    transform: none;
    background: none
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner {
    background-color: #0048b2;
    color: white;
}

.ant-picker-panels .ant-picker-content
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after,
.ant-picker-panels .ant-picker-content
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    display: none;
}
.ant-picker-panels .ant-picker-content
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::before,
.ant-picker-panels .ant-picker-content
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::before {
        background: #0048b2;
    transition: none;
    content: "";
    border-radius: 4px;
    right: 0;
    }

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background-color: inherit;
}

.ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
.ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: #0048b2;
    transition: none;
    content: "";
    border-radius: 4px;
    right: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::before {
    background: #0048b2;
    transition: none;
    content: "";
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
    display: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    display: none;
}

.ant-picker-header-view {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2e2c34;
}

.ant-picker-header .ant-picker-prev-icon,
.ant-picker-header .ant-picker-next-icon {
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 40%;
}

.ant-picker-header .ant-picker-header-prev-btn,
.ant-picker-header .ant-picker-header-next-btn {
    background-color: #b6b4ba;
    background: #b6b4ba;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    justify-content: center;
}
.ant-picker-date-panel.ant-picker-content th,
.ant-picker-content td {
    right: 0;
}

.ant-picker-header .ant-picker-prev-icon {
    width: 11px;
    height: 10px;
}
.ant-picker-header .ant-picker-next-icon {
    width: 6px;
    height: 8px;
}

.ant-picker-header .ant-picker-header-prev-btn:hover {
    background-color: #757575;
}
.ant-picker-header .ant-picker-header-next-btn:hover {
    background-color: #757575;
}
.ant-picker-header .ant-picker-prev-icon::before,
.ant-picker-header .ant-picker-next-icon::before,
.ant-picker-header .ant-picker-super-prev-icon::before,
.ant-picker-header .ant-picker-super-next-icon::before {
    border-width: 2.5px 0 0 2.5px;
    height: 9px;
    width: 9px;
}
