@import '../../../../mixins.scss';

.technical-monitoring {
  @include card-styles();

  height: 89vh;
  margin-top: 16px;
  flex: none;

  &__content {
    flex-direction: row;
    padding: 24px;
  }
  &__log-filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
    padding: 10px;
    flex: 1;
  }

  &__left {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    width: 71%;

    &_btns {
      display: flex;
      flex-direction: row;
      cursor: pointer;
    }
  }
  &__charts {
    margin-top: 24px;
  }

  &__list-msg {
    overflow-y: auto;
    margin-bottom: 50px;
  }

  &__title {
    color: var(--fourth-color);
    font-weight: normal;
    font-size: 16px;
  }
}
