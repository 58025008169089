.start {
  padding: 24px;
  .start {
    &__top-content {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }

    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 60%;
      }
      &--code {
        flex-basis: 35%;
      }
    }
  }
  .table-attr-row {
    display: flex;
    justify-content: space-between;
    &_value {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_tooltip {
      cursor: pointer;
    }
  }
  .data-table {
    height: 400px;
  }

  .modal__box {
    box-shadow: none;
  }
}
