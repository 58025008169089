.script {
  display: flex;
  flex-direction: column;
  flex-basis: 62%;
  padding: 52px 20px;

  &__top-content {
    margin: 0 0 38px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  &__bottom-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }

  &__field {
    &--name {
      grid-column: span 2;
    }

    &--code {
      grid-column: span 2;
    }

    &--description {
      grid-column: span 6;
    }
  }
}

.script__wrapper {
  display: flex;
  flex-direction: row;
}

