.add-function {
  padding: 24px;

  &__search {
    margin: 6px 0 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &-field {
      margin-right: auto;
      width: 252px;
    }
  }

  &__info-func {
    margin: 0;
    margin-right: auto;
  }
}
