.expression-editor {
  padding: 24px;
  &__btn-group-top {
    display: flex;

    button {
      &:first-child {
        margin-right: 17px;
      }
    }
  }
  &__expression {
    margin-top: 30px;

    textarea {
      height: 282px;
      resize: none;
      overflow-y: scroll;
    }
  }
  &__validate {
    height: 68px;
    padding: 13px 24px;
    background-color: var(--second-bg-color);
    border-top: 1px solid #ebeaed;
    button {
      margin-left: auto;
    }
  }
  &__btn {
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0.25px;
    > span {
      font-size: medium;
      margin-right: 11px;
    }
  }
}
