.choose-aggregate-modal {
  display: flex;
  flex-direction: column;
  padding: 30px 24px;

  &__search {
    margin-bottom: 20px;
    max-width: 252px;
  }

  &__table {
    width: 100%;
    max-height: calc(100vh - 310px);

    .data-table {
      height: 350px;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: inset 0 0 0 1px #000;
    }
  }
}
