.save-as {
  padding: 30px 24px;

  &__row {
    display: grid;
    grid-template-columns: 180px 1fr;
    gap: 12px;
    align-items: center;
  }

  &__row + &__row {
    margin-top: 24px;
  }

  &__label {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
  }
}
