.input-value-modal {
  &__content {
    padding: 30px 15px 24px;
  }
  &__btns {
    margin-top: 45px;
    padding-left: 9px;
  }

  &__txt {
    width: 100%;
    min-height: 78px;
  }
}
