@import '../../../../../../mixins.scss';

.complex-type-view {
  padding: 30px 24px;

  &__name {
    width: 55%;
  }

  &__table-wrapper {
    @extend %table;
    margin-top: 20px;
    max-height: 50vh;
  }
}
