.choose-table {
  padding: 30px 24px 24px 24px;

  &__search {
    width: 252px;
  }
  &__table {
    margin-top: 30px;
    height: 436px;
  }
}
