@import '../../../../mixins.scss';

.create-environment-modal {
  padding: 30px 24px;
  display: grid;
  gap: 24px;

  &__main-settings {
    width: 560px;
  }

  &__additional-settings {
    @extend %table;
  }

  &__streaming-platform {
    width: 500px;
  }

  &__authentication {
    padding: 24px;
    background: #fbfafc;

    &-field-group {
      width: 420px;
    }
  }

  &__cache-parameters .modal__box-content {
    padding: 24px;
    background: #fbfafc;

    .field-group {
      width: 410px;
    }
  }
}
