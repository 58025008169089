.connections__collapse {
  .connections__accordion-header {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5e6366;
  }

  .connections__accordion-toolbar {
    margin: -16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .ant-collapse-item-active {
    .connections__accordion-toolbar {
      opacity: 1;
      visibility: visible;
    }
  }

  .ant-collapse-item {
    box-shadow: 0 0 8px rgba(73, 95, 172, 0.15);
    border-radius: 4px !important;
  }

  .ant-collapse-item + .ant-collapse-item {
    margin-top: 24px;
  }

  .ant-collapse-header {
    padding: 15px 20px !important;
    display: flex;
    align-items: center;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
    border-top: 1px solid #edeff1;
  }

  .ant-table-collapse {
    padding-left: 0;
    padding-right: 0;

    .ant-table-row-indent + .ant-table-row-expand-icon {
      margin-top: 0;
      margin-right: 0;
    }
  }

  .ant-table-row {
    .input-ext__container,
    .select-ext__container {
      margin: -12px 0;
    }
  }

  .ant-form-item-has-error {
    .ant-input, .ant-select-selector {
      border: 1px solid var(--error-color) !important;
    }
  }

  .ant-form-item-explain {
    position: absolute;
    bottom: -32px;
  }

  .ant-row.ant-form-item {
    margin: 0;
  }

  .ant-row.ant-form-item.ant-form-item-has-error {
    margin-bottom: 10px;
  }
}