.ruleset {
  padding: 24px;

  &__top {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-grid {
      display: grid;
      grid-template-columns: 440px 270px;
      gap: 24px 48px;
    }

    &-actions {
      display: flex;
      gap: 12px;
    }
  }

  &__rules {
    .ruleset__checkbox-list {
      display: grid;
      gap: 24px;
    }

    .checkbox-item-template__checkbox {
      margin: auto 20px auto 0;
      padding: 0;
    }
  }
}

.ruleset-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  $ruleType: #{&}__field--ruleType;
  $applyrule: #{&}__field--applyrule;

  &__row {
    margin-right: 80px;
    display: flex;
    gap: 48px;
  }

  &__field {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    &-label {
      margin: 0 16px 0 0;
    }

    &-input {
      flex: 1;

      #{$ruleType} & {
        max-width: 250px;
      }

      #{$applyrule} & {
        flex: initial;
      }
    }

    &#{$applyrule} {
      justify-content: initial;
    }
  }
}
