.subdiagram-modal {
  padding: 30px 24px 24px;

  .subdiagram-modal {
    &__top-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 24px;
    }
    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 36%;
      }
      &--code {
        flex-basis: 22%;
        margin-left: 47px;
      }
      &--description {
        flex-basis: 62%;
      }
      &--select-diagram {
        flex-basis: 22%;
      }
      &--select-version {
        flex-basis: 36%;
        margin-left: 47px;
      }
    }
    &__btn--create-diagram {
      margin-left: 24px;
      button {
        height: 48px;
      }
    }
    &__tables {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      .custom-code-modal__table {
        height: 224px;
      }
    }
  }
  .table-attr-row {
    display: flex;
    justify-content: space-between;
    &_value {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_tooltip {
      cursor: pointer;
    }
  }
}
