.connect-data-source-modal {
  padding: 24px;

  &__status {
    margin-bottom: 24px;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.0156rem;

    &--success {
      color: #007f00;
    }

    &--error {
      color: #d30000;
    }
  }

  &__grid {
    display: grid;
    gap: 24px;
  }

  &__row {
    display: grid;
    align-items: center;
    grid-template-columns: 240px 1fr;
  }

  &__label {
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.0156rem;
    color: #5e6366;
  }
}
