@import '../../../../mixins.scss';

// .administration-page,
// .administration-page .content {
//   height: 100% !important; //
// }

.external-service-call-settings-modal {
  padding: 0 24px 30px;

  &__row {
    border-bottom: 1px solid #ebebeb;
  }

  &__status {
    margin-bottom: 24px;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.0156rem;

    &--success {
      color: #007f00;
    }

    &--error {
      color: #d30000;
    }
  }

  &__top {
    margin-bottom: 30px;
    margin-top: 30px;
    padding-right: 80px;
    display: flex;
    justify-content: space-between;
  }

  &__name {
    width: 436px;
  }

  &__accordion {
    &-icon {
      color: #5e6366;
      vertical-align: middle !important;
      transition: transform 0.2s linear;
    }

    &-header {
      display: flex;
      flex: 1 1;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #5e6366;
    }

    &-toolbar {
      margin: -16px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

      .ant-collapse-item-active & {
        opacity: 1;
        visibility: visible;
      }
    }

    .ant-collapse-item {
      box-shadow: 0px 0px 8px rgba(73, 95, 172, 0.15);
      border-radius: 4px !important;
    }

    .ant-collapse-item + .ant-collapse-item {
      margin-top: 24px;
    }

    .ant-collapse-header {
      padding: 15px 20px !important;
      display: flex;
      align-items: center;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
      border-top: 1px solid #edeff1;
    }

    .ant-table-collapse {
      padding-left: 0;
      padding-right: 0;

      .ant-table-row-indent + .ant-table-row-expand-icon {
        margin-top: 0;
        margin-right: 0;
      }
    }

    .ant-table-row {
      .input-ext__container,
      .select-ext__container {
        margin: -12px 0;
      }
    }
  }
}

.external-service-call-settings-modal-general-settings {
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 40px;
  background-color: #fbfafc;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5e6366;
  }

  &__field {
    width: 222px;

    &--large {
      width: 272px;
    }

    &--small {
      width: 70px;
    }

    &--number {
      width: 90px;
    }
  }
}

.external-service-call-settings-modal-request-generating {
  // &__text-editor {
  //   padding: 16px;
  //   height: 300px;
  // }

  &__text-editor textarea {
    padding: 16px !important;
    height: 300px;
    border: none !important;
    box-shadow: none !important;
    resize: none;
  }
}
