.ext-service-call {
  padding: 30px 24px 24px 24px;

  &__info {
    display: grid;
    max-width: 752px;
    gap: 24px;
  }

  &__top-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 48px;
    align-items: flex-end;
  }

  &__bottom-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  &__tables {
    margin: 30px 0 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .input-ext__with-button {
    padding: 0;
  }

  // .input-ext__container {
  //   margin: -12px 0;
  // }

  .input__version {
    margin-left: 47px;
  }
}
