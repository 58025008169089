.not-found {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 3em;
  }
}
