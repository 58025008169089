%branching-field-label {
  width: 28%;
  flex-shrink: 1;
  text-align: left;
}

%branching-field-input {
  width: 62%;
  flex-shrink: 1;
  margin-right: 10%;
}

.branching {
  padding: 20px 24px 30px;
  .branching {
    &__top-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 24px;
    }
    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 57%;
      }
      &--code {
        flex-basis: 37%;
        margin-left: 47px;
      }
      &--description {
        flex-basis: 100%;
      }
      &--condition {
        flex-basis: 36%;
      }
      &--additional {
        flex-basis: 36%;
      }
    }
  }
  .table-attr-row {
    display: flex;
    justify-content: space-between;
    &_value {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_tooltip {
      cursor: pointer;
    }
  }
  &__table {
    &-checkbox > span {
      margin: 0 8px 0 0;
    }

    .ant-table {
      height: 319px;
    }

    .input-ext__container,
    .select-ext__container {
      margin: -12px 0;
    }

    .default-row .ant-table-selection-column .ant-checkbox-wrapper {
      display: none;
    }
  }
}
