.data-sources-info {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  max-height: 100%;
  box-shadow: 0 0 8px rgba(73, 95, 172, 0.15);
  border-radius: 8px 0 0 8px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: opacity ease 0.2s, visibility ease 0.2s, transform ease 0.2s;
  z-index: 11;

  &--active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  &__top {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f6fb;
  }

  &__title {
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__close {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__content {
    padding: 24px;
    flex: 1;
    background-color: #fff;
    overflow-y: auto;
  }

  &__actions {
    display: flex;
    gap: 24px;
  }

  &__divider {
    margin: 24px 0;
    height: 1px;
    background-color: #ebeaed;
  }
}