.layout {
  &__wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--bg-color);
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    min-width: 1440px;
  }

  &__header {
    width: 100%;
    height: 60px;
  }

  &__main {
    position: relative;
    display: grid;
    grid-template: 1fr 40px / auto 1fr;
    width: 100%;
    height: calc(100vh - 60px);
  }

  &__right-side-wrapper {
    width: 100%;
    flex-shrink: 1;
    height: 100%;
    overflow: auto;
  }

  &__content-wrapper {
    overflow-y: scroll;
  }

  &__sider {
    grid-row: span 2;
    &.sider {
      background-color: #fff;
    }
  }

  &__footer {
    margin-left: 1px;
    height: 40px;
    width: calc(100% - 1px);
    z-index: 10;
  }
}

.sider {
  position: relative;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9;

  $panel: &;

  &__content {
    height: 100%;
    flex-shrink: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    margin-top: auto;
    display: flex;
    height: 41px;
    flex-direction: column;
    border-top: 1px solid #f0f0f0;

    &-button {
      padding: 9px 16px;
      display: block;
      background: none;
      border: none;
      cursor: pointer;
      text-align: left;

      svg {
        transition: transform 0.3s ease-in-out;
      }

      #{$panel}.ant-layout-sider-collapsed & {
        svg {
          display: inline-block;
          transform: rotateY(180deg);
        }
      }
    }
  }

  &__right-trigger {
    position: absolute;
    top: 50%;
    left: 100%;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 64px;
    background: none;
    border: none;
    transform: translateY(-50%);
    cursor: pointer;
    filter: drop-shadow(3px 0 2px rgba(0, 0, 0, 0.08));
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
    }

    svg {
      position: relative;
      display: inline-block;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%;
  }
}
