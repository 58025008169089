.connections__data-source-modal {
  padding: 24px 20px;

  .connections__data-source-modal_border {
    border: 1px solid #C6CACC;
    border-radius: 5px;
    margin: -11px -13px;
    padding: 11px 13px;
    top: 10px;
    position: relative;
  }
}

.connections__test-status {
  text-align: center;
  width: 100%;
}
.connections__test-status-success {
  background-color: #7db700;
}
.connections__test-status-incomplete {
  background-color: #800000;
  color: white;
}
.connections__test-status-failed {
  background-color: #FFCC00;
}

div.modal__container {
    overflow: auto;
}