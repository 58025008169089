@import '../../mixins.scss';

.home {
  @include card-styles();
  &__content {
    max-height: 112px;
    background-color: #e5efff;
    border-radius: 8px;
    max-width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
    &-icon {
      width: 64px;
      height: 64px;
      border-radius: 76px;
      background-color: #fff;
      margin-right: 18px;
      display: flex;
      justify-content: center;
    }
    &-left {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    &-right {
      display: flex;
    }
  }
  &-icon {
    align-self: center;
  }
}

.home-button {
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
}
