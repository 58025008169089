.data-grid-list {
  position: relative;

  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    z-index: 10 !important;
  }

  &__grid-container {
    .dx-datagrid-headers {
      font-weight: 700;
    }
    .dx-datagrid-nowrap,
    .dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
      white-space: normal;
    }
  }
}
