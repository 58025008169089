@import '../../../mixins.scss';

.ant-modal-root {
  .ant-modal {
    padding-bottom: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    box-shadow: 0px 0px 8px rgba(73, 95, 172, 0.15);
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-header {
    padding: 0;
    border-bottom: none;
    border-radius: 0;
  }

  .ant-modal-body {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .ant-modal-footer {
    padding: 24px;
    border-top: none;

    .btn-group-bottom {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }

    .btn-left {
      margin-right: auto;
    }
  }

  .ant-modal .ant-modal-body:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: none;
    transition: box-shadow ease-in-out 0.2s;
    pointer-events: none;
  }

  .ant-modal.not-scrolled .ant-modal-body:after {
    box-shadow: inset 0px -2px 8px rgba(73, 95, 172, 0.15);
  }
}

.modal {
  background: none;
  font-size: 14px;
  overflow: auto;

  &__container {
    z-index: 2;
    background: var(--modal-bg-color-container);
    background: none;
    opacity: 1;
    visibility: visible;
    overflow: hidden;
  }

  &__body {
    background-color: var(--modal-bg-color);
  }

  &__header {
    padding: 16px 20px;
    display: flex;
    background-color: var(--modal-header-color);

    &_border {
      border-bottom: 1px solid #ebeaed;
    }

    h2 {
      margin: auto 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: rgba(#000, 0.87);
    }
  }

  &__actions {
    margin: 0 0 0 auto;
    display: flex;

    .img__close {
      cursor: pointer;
    }

    .img__close:not(:last-child) {
      margin: 0 5px 0 0;
    }
  }

  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px rgba(73, 95, 172, 0.15);
    border-radius: 4px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar(6px, 2px, #b6b4ba);

    &-header {
      padding: 16px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #edeff1;
    }

    &-icon {
      margin: -2px 10px -1px 0;
      display: block;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    &-title {
      font-weight: normal;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.25px;
      color: #5e6366;
    }

    &-toolbar {
      margin: -9px 0 -9px auto;
      display: flex;
      align-items: center;
    }

    &-divider {
      margin: 0 20px;
      width: 1px;
      height: 20px;
      background: #c6cacc;
    }

    &-content {
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbar(6px, 2px, #b6b4ba);
    }

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__img {
    margin: 0 8px 0 0;
  }
}

.vertical-expanded {
  min-height: calc(100vh - 185px);
}
