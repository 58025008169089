.development-page {
  margin: 16px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);

  &__tabs {
    margin: 0 0 16px 0;

    &--empty {
      margin: 0;
    }
  }
}
