@mixin card-styles {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(73, 95, 172, 0.15);
  border-radius: 8px;
  text-align: left;
  overflow: hidden;

  &__top {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    background-color: #f5f6fb;
    border-bottom: 1px solid #ebebeb;
  }

  &__title {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.44px;
    color: rgba(#000, 0.87);

    &-icon {
      margin: 0 8px 0 0;
    }
  }

  &__subtitle {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(#000, 0.54);
  }
  &__sublabel {
    color: var(--second-color);
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    letter-spacing: 0.25px;
  }

  &__actions {
    margin: 0 0 0 auto;
    display: flex;

    &-button {
      padding: 0;
      display: flex;
      background: none;
      border: none;
      cursor: pointer;

      svg {
        display: flex;
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      &:not(:last-child) {
        margin: 0 5px 0 0;
      }
    }
  }

  &__row {
    &:not(:empty) {
      border-bottom: 1px solid #ebebeb;
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  &__zone {
    margin-top: 24px;
    background: #fff;
    border-radius: 4px;
    filter: drop-shadow(0px 0px 8px rgba(73, 95, 172, 0.15));
  }
}

@mixin scrollbar(
  $width: 0,
  $border-radius: 0,
  $thumb-color: 'transparent',
  $track-color: 'transparent'
) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: $border-radius;
  }
}

@mixin line-clamp($lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

%field-container {
  position: relative;
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
  }

  &__required {
    position: absolute;
    left: 100%;
    bottom: 10px;
    margin-left: 5px;
    color: #ea5455;
  }
}

%table {
  .gray {
    color: #5e6366;
  }

  .success {
    color: #007f00;
  }

  .error {
    color: #d30000;
  }

  .ant-table-cell {
    height: 58px;
  }

  .input-ext__container,
  .select-ext__container {
    margin: -12px 0;
  }

  .button_icon {
    margin: -12px;
  }
}

.balalaika__table {
  tr.ant-table-row.active-row > td,
  tr.ant-table-row.active-row:hover > td {
    background: lighten(#0e3c99, 60%);
  }

  .ant-table-thead {
    > tr > th {
      background: #fafafa;
    }

    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      content: none;
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 2px;
      transform: translate(0, 0);
    }

    .ant-checkbox-inner::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7px;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: initial;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-column-sorter {
    margin-left: 10px;
  }

  .ant-table:not(.ant-table-small):not(.ant-table-middle) {
    .ant-table-selection-col {
      min-width: 56px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f7f9fc;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td,
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #e5efff;
  }
}

.balalaika-p-24 {
  padding: 24px;
}