@import '../../../../mixins.scss';

.communication-channel-add-modal {
  padding: 30px 24px 6px;

  &__top {
    margin: 0 0 30px 0;
    display: grid;
    width: 784px;
    grid-template-columns: 270px auto 270px;
    gap: 24px 48px;

    > div:nth-child(1) {
      grid-column: 1 / span 2;
    }
  }

  &__tables {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 24px;

    .modal__box-content {
      @extend %table;
    }
  }

  &__fields {
  }

  &__variables {
  }
}
