.approve-modal {
  &-content {
    display: flex;
    justify-content: center;
    padding: 50px;
  }
}

.name-of-filters {
  // font-family: "Roboto";
  // font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: "4px";
  color: #0048B2;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor:pointer
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 4px;
  background-color: #333;
  border-radius: 5px
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.dot {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  background-color: #bbb;
  border-radius: 50%;
}

.filter-tab{
  border-radius: "4px";
  color: "black"
}
