@import '../../../../../../../mixins.scss';

$main-class: '.communication-modal';

#{$main-class} {
  padding: 24px;

  &__info {
    margin: 0 0 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px 48px;

    > div:nth-child(1) {
      grid-column: 1 / span 2;
    }

    > div:nth-child(3) {
      grid-column: 1 / span 3;
    }
  }

  &__tabs {
    margin: 0 0 24px 0;
  }
}

#{$main-class}-settings {
  position: relative;

  &__row {
    display: grid;
    grid-template-columns: 220px 1fr;
    align-items: center;

    & + & {
      margin: 24px 0 0 0;
    }

    &--top {
      align-items: start;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
  }

  &__field {
    display: flex;

    &--max-width {
      flex-direction: column;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

#{$main-class}-communication-variables .modal__box-content,
#{$main-class}-output-variables .modal__box-content {
  @extend %table;
}
