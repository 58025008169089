.interval-value {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: stretch;

  & > span {
    display: block;
    width: 1px;
    height: 30px;
    background-color: #f5f6fb;
    flex-shrink: 0;
  }

  .ant-checkbox-wrapper {
    &:first-of-type {
      margin-right: 13px;
    }
    &:last-of-type {
      margin-left: 13px;
    }
  }
  .input-ext__container {
    border: 1px solid #f5f6fb;
    .ant-input {
      height: 38px;
    }
  }
  &__input-to {
    margin-left: -1px;
    border-radius: 0 4px 4px 0;
  }
  &__input-from {
    border-radius: 4px 0 0 4px;
  }
}
