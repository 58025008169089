.invalid-data {
  &__btn {
    background-color: #ff3a006e;
    margin-left: 0;
    margin-right: auto;
  }
  &__msg {
    margin: 0;
  }
}
