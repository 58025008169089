.connections__common-settings {
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px 40px;
  gap: 24px 40px;
  background-color: #fbfafc;

  .field-group__field {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .input-ext__container {
    text-align: right;
  }
}