.editor-diagram {
  padding: 30px 24px;

  &__search {
    margin-bottom: 30px;
    max-width: 252px;
  }

  .blue {
    color: #0048b2;
  }

  .disabled-row .blue {
    color: #dcdcdc;
  }
}
