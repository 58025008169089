.charts {
  .chart {
    height: 25vh;
  }
  &__btns {
    margin: 0 auto;
  }

  .chart-btn {
    margin-right: 8px;
    padding: 2px 8px;
    &:hover {
      // background-color: var(--nav-bg-color-hover); ???
    }
  }
}
