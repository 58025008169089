.reading-data {
  padding: 30px 24px 24px 24px;

  &__top {
    display: grid;
    max-width: 752px;
    grid-template-columns: 2fr 1fr;
    gap: 48px;
  }

  &__grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(100px, 320px) minmax(100px, 280px);
    gap: 24px;
  }

  &__data {
    grid-row: span 2;

    &-db {
      &-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-divider {
        margin: 0 20px;
        width: 1px;
        height: 20px;
        background: #c6cacc;
      }

      &-actions {
        margin: -8px 0;
        display: flex;
        align-items: center;
      }
    }

    .modal__box-header {
      padding: 0;
      justify-content: center;
    }
  }

  &__query {
    .modal__box-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-txt {
      display: flex;
      width: 100%;
      height: 100%;
      outline: none;
      overflow: auto;

      .ant-input.input-ext {
        resize: none;
      }
    }

    &-top {
      flex: 1;
    }

    &-bottom {
      padding: 14px 24px;
      display: flex;
      justify-content: flex-end;
      background-color: #fbfafc;
    }
  }

  &__maping {
    max-height: 280px;
  }

  .table-attr-row {
    display: flex;
    justify-content: space-between;

    &_value {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .image-key {
    opacity: 0.1;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5e6366;
    border-color: #5e6366;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5e6366;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #5e6366;
  }

  .simple-table-wrapper .input-ext__container {
    margin: -12px 0;
  }
}

.query-error {
  color: red;
}
