@mixin primaryFont {
    margin: 0;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(#000, 0.87) !important;
  }
  
  @mixin secondaryFont {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5e6366 !important;
  }
  
.information-changes {
  margin: 0 0 0 62px;
  flex-basis: 38%;
  &__title {
    @include primaryFont();
  }
  &__date {
    @include secondaryFont();
    margin-bottom: 10px;
  }
  &__author {
    @include secondaryFont();
  }
  &__block {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    margin-bottom: 32px;
  }
}

.top-border {
  border-top: solid 1px #ebeaed;
}

.bottom-border {
  border-bottom: solid 1px #ebeaed;
  padding-bottom: 16px;
}