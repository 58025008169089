@import '../../../../mixins.scss';

.lockout {
  @include card-styles();
  margin: 16px 0;

  &__content {
    position: relative;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    flex: 1 1;

    .ant-table-thead {
      position: sticky;
      top: 0;
      background-color: #fafafa;
      z-index: 10 !important;
    }
  }

  &__left {
    box-shadow: 0px 3px 4px rgba(73, 95, 172, 0.15);
    overflow: auto;
  }

  &__right {
    overflow: auto;
    // padding: 24px 30px;
    width: 344px;
  }

  &__tests {
    position: relative;
  }
}

.modal__child {
  padding: 30px 24px;
}

.date-filters {
  // width: 286px;
  border-radius: 4px;
  // box-shadow: 0 0 8px rgba(73, 95, 172, 0.15);

  &__top {
    height: 54px;
    padding: 18px;
    background: #F5F6FB;
    box-shadow: 0 1px 1px rgba(7, 9, 1, 0.35);
  }

  &__title {
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__content {
    padding: 24px;
  }

  &__radio {
    border-bottom: 1px solid #EBEAED;
    padding-bottom: 24px;
  }

  &__picker {
    padding-top: 26px;

    &_title {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 16px;
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

}

.unlock-body {
  padding: 30px 25px 25px;
}
