@import '../../mixins.scss';

.notification-clear-button {
  position: fixed;
  top: 65px;
  right: 10px;
  padding: 5px 11px;
  background: #fafafc;
  border: 1px solid #eaeaed;
  border-radius: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  z-index: 1010;
}

.notification-message {
  position: relative;
  padding-right: 12px;

  &__text {
    &--line-clamp {
      @include line-clamp();
    }
  }

  &__icon {
    position: absolute;
    top: 3px;
    right: 0;
    color: #919699 !important;
    cursor: pointer;

    &--top {
      transform: rotate(180deg);
    }
  }
}

.ant-notification.ant-notification-topRight {
  margin-right: 10px;

  .ant-notification-notice {
    padding: 9px 15px;
    margin-bottom: 3px;
    border-radius: 2px;
    box-shadow: none;
  }

  .ant-notification-notice-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  .ant-notification-notice-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }

  .ant-notification-notice-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }

  .ant-notification-notice-error {
    background-color: #fff1f0;
    border: 1px solid #ffccc7;
  }

  .anticon.ant-notification-notice-icon {
    display: none;
  }

  .ant-notification-notice-message {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
  }

  .ant-notification-notice-close {
    top: 12px;
    right: 16px;

    .ant-notification-close-x {
      display: flex;

      svg {
        color: #919699;
      }
    }
  }
}
