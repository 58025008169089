.ant-row.connections__edit-cell {
  display: block;
  margin: 0;

  .ant-col.ant-form-item-control {
    display: block;
  }

  .ant-form-item-control-input {
    display: block;
  }

  .ant-form-item-explain {
    position: absolute;
    bottom: -23px;
  }

  .ant-input.ant-input-sm.input-ext {
    border: 1px solid #d9d9d9 !important;
    min-height: 32px;
    min-width: auto;
    width: 100%;
  }
}

.ant-row.connections__edit-cell.ant-form-item-has-error {
  .ant-input.ant-input-sm.input-ext {
    border: 1px solid #ff4d4f !important;
  }
}

.connections__edit-wrap {
  width: auto;
  .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed,
  .ant-table-row-expand-icon.ant-table-row-expand-icon-spaced {
    display: none;
  }
}