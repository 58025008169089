.side-panel {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  height: 100%;

  $panel: &;

  &__header {
    margin: 0 0 10px 0;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    &-icon {
      margin: 0 16px 0 2px;
      display: inline-block;
    }

    .ant-layout-sider-collapsed &-text {
      opacity: 0;
    }
  }

  &__menu {
    border-right: none !important;

    &-icon {
      display: inline;
      vertical-align: middle;
      color: #5e6366;
      transition: color 0.3s ease-in-out !important;

      .ant-menu-submenu-selected &,
      .ant-menu-submenu-active &,
      .ant-menu-item-selected &,
      .ant-menu-item-active & {
        color: #0e3c99;
      }

    }

    .ant-menu-item:not(.ant-menu-item-only-child),
    > .ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .ant-menu.ant-menu-sub.ant-menu-inline {
      background: none;
    }



    &.ant-menu-inline {
      .ant-menu-submenu.ant-menu-submenu-inline {
        &.ant-menu-submenu-selected,
        &.ant-menu-submenu-active {
          color: #0048b2;
          .ant-menu-submenu-title {
            .icon.side-panel__menu-icon.ant-menu-item-icon {
              color: #0048b2;
            }
          }
        }

        &.ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
          .ant-menu-submenu-title {
            background-color: #e5efff;
          }
        }
      }

      .ant-menu-item.ant-menu-item-only-child {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 9px;
        padding-bottom: 9px;
        height: auto;
        min-height: 40px;
        width: 100%;
        background: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #0048b2;
        white-space: initial;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 20px;
          bottom: 0;
          right: 0;
          background-color: #e5efff;
          opacity: 0;
        }

        .ant-menu-title-content {
          position: relative;
        }

        &.ant-menu-item-selected {
          background: none;

          &::before {
            opacity: 1;
          }
        }

        &.ant-menu-item-active {
          background: none;

          &::before {
            background-color: #f5f6fb;
            opacity: 1;
          }
        }
      }
    }
  }
}

// TODO: Не смог понять, ка сделать этот стиль не глобальным
// Это фон опции в режиме свернутого меню
.ant-menu-sub.ant-menu-vertical {
  li.ant-menu-item-active {
    background-color: #f5f6fb;
  }
}