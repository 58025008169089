@import '../../../mixins.scss';

%input {
  padding: 11px;
  border-radius: 4px;
  border: 1px solid #c6cacc;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}

.textarea-with-button-container {
  @extend %field-container;

  &__button {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.textarea-with-button.ant-input {
  @extend %input;
}
