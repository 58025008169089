@import '../../../../mixins.scss';

.deploy-diagram {
  @include card-styles();
  margin: 16px 0;

  &__content {
    position: relative;
    overflow-y: auto;

    .ant-table-thead {
      position: sticky;
      top: 0;
      background-color: #fafafa;
      z-index: 10 !important;
    }
  }
}

.remote-scripts-checkbox {
  padding: 20px;
  display: flex;
  
  label > span {
    align-self: center;
  }
  label > span:nth-child(2) {
    width: 240px;
    margin-left: 12px;
    color: var(--fifth-color);
  }
}

.filters-and-checkbox-box{
  display: flex;
  border-top: 1px solid #c6cacc;
}
.filters-and-checkbox-box__filters{
  display: grid;
}

.modal__child {
  padding: 30px 24px;
}

.ant-table-filter-column {
  justify-content: flex-start;
}
