.input-range-modal {
  margin-bottom: 66px;
  .min-max-inputs {
    &__label {
      margin: 0;
      line-height: 30px;
      background-color: #fff;
      border: 1px solid #000;
    }

    &__input {
      &:focus-visible {
        border-radius: 0;
      }
    }

    &__btns-check {
      margin-top: 15px;
      display: flex;
      text-align: left;
    }
    &__check-from,
    &__check-to {
      flex-basis: 50%;
    }
  }
}
