@import '../../../../mixins.scss';

.communication-field-settings-modal {
  padding: 30px 24px 6px;
  display: grid;
  gap: 24px;

  &__user {
    @extend %table;
  }

  &__list {
    display: grid;
    gap: 24px;
  }
}
