.schemas__table {
  position: relative;

  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    z-index: 10 !important;
  }
}

.data-types {
  &__cell-edit {
    display: flex;
    justify-content: space-between;

    button {
      margin: -8px 0;
    }
  }
}

.custom-type-edit {
  padding: 30px 24px;

  &__name {
    width: 55%;
  }

  &__table-wrapper {
    margin-top: 20px;
    max-height: 50vh;
  }

  &__checkbox-cell {
    text-align: center;
  }
}

.custom-type-tabs-wrapper {
  border-bottom: solid 1px #EDEFF1;
}

.schema-container {
  margin: 10px;
  height: 75vh;
}

.schema-json-wrapper {
  margin-top: 10px;
}