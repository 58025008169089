.field-group {
  display: grid;
  gap: 24px;

  &__row {
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1fr;
    gap: 24px;
  }

  &__label {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;

    & > span:nth-child(2) {
      color: #d30000;
    }
  }
}
