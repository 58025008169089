.toolbar {
  padding: 8px;
  display: flex;
  // background-color: var(--development-bg-color);
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;

  &__title {
    color: var(--second-color);
    padding-left: 16px;
    white-space: nowrap;
  }

  &__checkbox-all {
    margin-left: 12px;

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 2px;
        transform: translate(0, 0);
      }

      .ant-checkbox-inner::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }

  &__divider {
    margin: 0 20px;
    height: 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    &-button + &-button {
      margin-left: 8px;
    }

    &-icon {
      margin: auto;
      display: inline-block;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  #additional-buttons {
    flex-basis: 100%;
  }

  &__search {
    margin-left: auto;
    margin-right: 5px;
    display: flex;
    align-items: center;

    > input[type='text'] {
      height: 30px;
      width: 250px;
      border-radius: 0;
      border: 1px solid #000000;
    }
  }

  &__before-actions {
    margin: 0 0 0 20px;
    display: flex;
    align-items: center;
  }
}
