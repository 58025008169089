.log-filter {
  overflow-y: auto;
  margin-bottom: 39px;
  box-shadow: 0px 0px 8px rgba(73, 95, 172, 0.15);
  .card__content {
    padding: 24px;
  }
  .accordion {
    margin: -24px -24px 0 -24px;
    &__header {
      color: var(--fourth-color);
      font-weight: 500;
    }
    .ant-collapse-content-box {
      padding: 0px 24px 24px !important;
    }
  }
  .input-ext__search {
    height: 42px;
    border: 1px solid #eaeaed;
    border-radius: 4px;
    padding: 9px 16px;
    margin: 0;
    > input {
      border: none;
    }
    > img,
    > svg {
      padding: 2px 12px;
    }
  }
  &__type {
    padding: 24px 0;
  }
  &__accordion {
    padding-top: 26px;
  }
  .select-time {
    width: 100%;
  }
  .ant-picker-input > input {
    color: var(--second-color);
  }
}
