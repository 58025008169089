.calc-variables {
  padding: 24px;
  .calc-variables {
    &__top-content {
      margin-bottom: 30px;
      display: flex;
    }
    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 45%;
      }
      &--code {
        flex-basis: 28%;
        margin-left: 47px;
      }
    }
    &__tables {
      height: 421px;
    }
    &__table {
      height: 367px;

      .data-table {
        background-color: #fff;
        box-shadow: inset 0 0 0 1px #000;
        height: 300px;
        overflow-y: scroll;
        width: 100%;

        &__edit-cell {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__input-with-button {
          width: 100%;
        }
      }

      .input-ext__container {
        margin: -10px 0;
      }
    }
  }
}
