@import '../../../../../../mixins.scss';

.aggregate {
  @include card-styles();

  &__content {
    overflow: auto;
    display: flex;
    flex-direction: row;
  }

  .blue {
    color: #0048b2;
  }
}

.aggregate-info {
  padding: 30px 24px;
  flex-basis: 42%;
  margin-right: 260px;
  $description: #{&}__description;
  $dates: #{&}__dates;
  $field: #{&}__field;

  &__row {
    // display: grid;
    // grid-template-columns: 4fr 6fr;
    // gap: 30px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    // & + & {
    //   margin-top: 24px;
    // }
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: auto;
    &-label {
      flex: 1 1;
      font-size: 14px;
      line-height: 16px;
      color: #000000;

      #{$description} & {
        flex: initial;
      }

      #{$dates} #{$field}:first-child & {
        min-width: 110px;
      }

      #{$dates} #{$field}:last-child & {
        min-width: 240px;
      }
    }

    &-input {
      margin-left: 20px;
      flex: 1 1;

      #{$dates} #{$field} & {
        flex: initial;
      }
    }
  }

  &__top {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  &__name {
    // max-width: 710px;
  }

  &__description {
    // max-width: 754px;
    margin-top: 24px;
  }

  &__dates {
    grid-template-columns: auto auto 1fr;
  }

  &__relation {
    max-width: 580px;
    grid-template-columns: initial;
    gap: initial;
  }
}
