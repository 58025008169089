@import '../../../mixins.scss';

.timepicker-container {
  @extend %field-container;
}

.timepicker.ant-picker {
  padding: 11px;
  border-radius: 4px;
  border: 1px solid #c6cacc;

  .ant-picker-input input {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  .ant-picker-clear .icon {
    color: darken(#eeeeee, 10%);
  }
}
