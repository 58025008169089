.add-element {
  padding: 24px;

  &__search {
    margin: 8px 0 30px 0;
    display: flex;
    width: 252px;
    flex-direction: row;
  }

  &__table {
    .disabled-row {
      pointer-events: initial !important;
    }
  }

  &__array_flag_field {
    display: flex;

    > span:nth-child(1) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
