.language {
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 162px;
  height: 100%;

  font-size: 14px;
  line-height: 1.5;
  color: #f1f3f4;

  &__menu {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    margin: 0 14px 0 0;
    display: block;
    width: 30px;
  }
}
