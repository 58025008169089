.accordion {
  &__header {
    display: flex;
    align-items: center;
    color: var(--second-color);
    padding: 12px 12px 12px 8px;
    > svg {
      margin-right: 8px;
    }
  }
}
