.ant-tooltip {
  z-index: 20000;
}

.ant-tooltip-inner {
  padding: 0.625em !important;
  background-color: #202124 !important;
  border-radius: 0.25em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0275em !important;
}

.ant-popover-inner,
.ant-popover-arrow-content {
  background-color: var(--nav-bg-color-active) !important;
  color: var(--color) !important;
}

.tooltip-error {
  .ant-tooltip-inner,
  .ant-tooltip-arrow-content {
    background-color: #fff1f0 !important;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &-icon.icon {
      margin-top: 2px;
      margin-right: 8px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;
      color: #f5222d;
    }
  }
}

.tooltip-white {
  .ant-tooltip-inner,
  .ant-tooltip-arrow-content {
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
