.save-data {
  padding: 24px;

  &__top-content {
    margin-bottom: 30px;
    display: flex;
  }

  &__field {
    flex-basis: 100%;
    &--title {
      flex-basis: 40%;
    }
    &--code {
      flex-basis: 30%;
      margin-left: 40px;
    }

    &--type {
      flex-basis: 25%;
    }

    &--table {
      flex-basis: 25%;
      margin-left: 40px;
    }
  }

  &__tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
    margin-top: 30px;

    &__disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &__table {
      height: 250px;

      .ant-table-selection-col {
        width: 40px;
      }

      .input-ext__container {
        margin: -10px 0;
      }
    }

    &_row {
      display: flex;
      justify-content: space-between;
      &_value {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__select {
    background-color: #fafafc;
  }
}
