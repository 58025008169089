.input-ext {
  &__container {
    position: relative;
    text-align: left;

    .input-ext:not(textarea) {
      // border: 1px solid #c6cacc !important;
      height: 36px;
    }

    .ant-input-lg.input-ext:not(textarea),
    .ant-input-affix-wrapper-lg.input-ext:not(textarea) {
      height: 48px !important;
    }

    &--error .input-ext {
      border-color: #ffa39e !important;
    }

    &--error .input-ext:hover {
      border-color: #f5222d !important;
    }

    &--error .input-ext:focus,
    &--error .ant-input-affix-wrapper:focus,
    &--error .ant-input-affix-wrapper-focused {
      border-color: #f5222d !important;
      box-shadow: 0 0 0 2px rgba(#f5222d, 0.2) !important;
    }
  }

  &__top {
    margin-bottom: 8px;
    display: inline-block;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
    position: relative;
  }

  &__required {
    position: absolute;
    left: 100%;
    bottom: 10px;
    margin-left: 5px;
    color: #ea5455;
  }

  &__error {
    color: var(--error-color);
  }

  &.ant-input,
  &.ant-input-affix-wrapper {
    border-radius: 4px;
  }

  &.ant-input-lg {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.43;
  }

  &__with-button .ant-input-lg {
    font-size: 14px;
    line-height: 1.43;
  }

  &.ant-input-affix-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 5px !important;
  }

  // Todo проверить что поменялось
  // &__with-button  {
  //   padding: 8px 16px !important;
  // }

  &.ant-input-affix-wrapper input {
    height: 100%;
  }

  &__button {
    padding: 0;
    &:hover {
      background-color: transparent !important;
    }
  }

  &__add-data {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__input {
      width: 100%;
      .input-ext.ant-input-lg {
        padding-right: 42px;
        // pointer-events: none;
      }
    }

    &__button {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 5px;
      padding: 5px;
      transform: translate(0, -50%);
    }
  }
}
