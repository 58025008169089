@import '../../../../../../../../mixins.scss';

.confirm-import-objects-modal {
  padding: 30px 24px 0;

  &__description {
    margin: 0 0 30px 0;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__table {
    @extend %table;
  }
}
