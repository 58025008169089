.user-version {
  margin: 30px 24px;

  &__grid {
    display: grid;
    gap: 24px;
  }

  &__item {
    &--medium {
      max-width: 436px;
    }
  }

  &__textarea textarea {
    resize: none;
  }
}
