@import '../../../mixins.scss';

.tab-slider-panel {
  margin: -14px;
  padding: 14px;
  min-height: 72px;
  max-height: 72px;
  display: flex;
  align-items: center;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  transition-property: min-height, max-height, opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  $item: #{&}__item;
  $empty: #{&}--empty;

  &--empty {
    min-height: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__button {
    position: relative;
    margin: 0;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;

    svg {
      display: inline-block;
      color: rgba(#000, 0.87);
    }

    #{$empty} & {
      display: none;
    }

    &--prev {
      margin-right: 15px;
    }

    &--next {
      margin-left: 15px;
    }
  }

  &__scroll {
    margin: -14px;
    padding: 14px;
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__item {
    position: relative;
    margin-right: 15px;
    padding: 6px 12px;
    display: flex;
    width: 154px;
    min-width: 154px;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0 2px 2px rgba(73, 95, 172, 0.2);
    border-radius: 9px;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: #0e3c99;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: #e5efff;
      box-shadow: 0 5px 9px rgba(14, 60, 153, 0.4);
    }

    &-title {
      margin: 0 12px 0 0;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: rgba(#000, 0.87);
      text-align: left;
      line-break: anywhere;
      @include line-clamp(2);

      #{$item}.active & {
        font-weight: 500;
        color: #0e3c99;
      }
    }

    &-close {
      min-width: 16px;
      align-self: flex-start;
      color: rgba(#000, 0.87);

      #{$item}.active & {
        color: #0e3c99;
      }
    }
  }
}
