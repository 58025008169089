@import '../../../../../../mixins.scss';

.stream {
  @include card-styles();
  outline: none;

  &__content {
    position: relative;
    overflow: auto;
  }

  .stream__row-top {
    display: flex;
    .toolbar {
      position: absolute;
      flex-basis: auto;
    }
  }
}
