.accordion-new {
  &__icon {
    color: #5e6366;
    vertical-align: middle !important;
    transition: transform 0.2s linear;
  }

  &__header {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5e6366;
  }

  &__toolbar {
    margin: -16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    .ant-collapse-item-active & {
      opacity: 1;
      visibility: visible;
    }
  }

  .ant-collapse-item {
    box-shadow: 0px 0px 8px rgba(73, 95, 172, 0.15);
    border-radius: 4px !important;
  }

  .ant-collapse-item + .ant-collapse-item {
    margin-top: 24px;
  }

  .ant-collapse-header {
    padding: 15px 20px !important;
    display: flex;
    align-items: center;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
    border-top: 1px solid #edeff1;
  }
}
