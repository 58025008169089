.choose-block {
  padding: 24px;

  &__search {
    margin: 6px 0 30px 0;
    position: relative;
    width: 252px;
  }

  &__table {
    max-height: 504px;
  }
}
