.reading-aggregate {
  position: relative;
  padding: 30px 24px;

  &__info {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px 48px;

    &-description {
      grid-column: span 2;
    }
  }

  &__grouping-elements {
    margin-top: 30px;
  }

  &__aggregates {
    margin-top: 30px;
    max-height: 278px;
  }

  &__input-with-button {
    margin: -9px 0;

    .ant-input-affix-wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
