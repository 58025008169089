.choose-custom-code {
  padding: 24px;

  &__search {
    margin: 6px 0 30px 0;
    display: flex;
    width: 320px;
    flex-direction: row;
    justify-content: space-between;
  }

  &__table {
    &-parent-cell {
      svg {
        margin-right: 5px;
      }
    }
    &-markered-cell {
      color: #0048b2;
    }
  }

  .form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    &__input {
      width: 320px;
    }
  }
}
