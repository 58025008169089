.diagrams-with-aggregate {
  &__simple-table {
    max-height: 65vh;
    overflow-y: auto;
  }
}

.toolbar-actions {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  flex-shrink: 1;
  align-items: center;

  &__search {
    width: 200px;
  }
}
.condition-status {
  > img,
  > svg {
    margin-right: 8px;
  }
}
.code {
  color: #1890ff;
}

.version {
  color: #0048b2;
}
