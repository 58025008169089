.aggregates-data-grid {
  position: relative;

  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    z-index: 10 !important;
  }

  &__context {
    margin: -16px;
    padding: 16px;
  }

  &__cell {
    text-overflow: ellipsis;
    overflow: hidden;

    &.blue {
      color: #0048b2;
    }
  }
}
