.input-ext__search {
  position: relative;
  display: flex;
  width: 100%;

  $large: #{&}--lg;

  &__input {
    width: 100%;

    .input-ext {
      padding-right: 36px;
      background-color: #fafafc;
    }

    #{$large} & .input-ext {
      padding-right: 48px;
    }
  }

  &__button {
    position: absolute;
    right: 0;
    padding: 6px;
    pointer-events: none;
    z-index: 10;

    #{$large} & {
      padding: 12px;
    }

    svg {
      margin: 0 !important;
      color: #c6cacc;
      transition: all 0.2s linear;
    }

    &--clear {
      pointer-events: initial;
      cursor: pointer;

      svg {
        color: #eeeeee;
      }

      &:hover svg {
        color: darken(#eeeeee, 10%);
      }
    }
  }
}
