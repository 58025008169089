.ant-table-cell {
  .table-cell__form-item {
    &_checkbox {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
    }
    &_inputWithButton {
      margin: -5px 0 !important;
      display: flex;
      align-items: center;
      input {
        padding-right: 34px;
        width: 100%;
        border: none;
        box-shadow: none;
      }
      span {
        border: none;
        background: inherit !important;
        svg {
          cursor: pointer;
        }
      }
    }
    &_select {
      .ant-select-selector {
        background: inherit !important;
      }
    }
  }
  .ant-form-item-control-input-content {
    input {
      background: inherit !important;
    }
  }
}

.ant-table-cell.cell-invalid {
  position: relative;
  box-shadow: inset 0 0 0 1px #ffa39e;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0px 9px 28px rgba(255, 163, 158, 0.05),
      0px 6px 16px rgba(255, 163, 158, 0.08),
      0px 3px 6px rgba(255, 163, 158, 0.12);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    z-index: 100;
  }

  &:hover::after {
    opacity: 1;
  }
}
