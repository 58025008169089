:root {
  --color: #000000;
  --second-color: #5e6366;
  --third-color: #fff;
  --fourth-color: #000000de;
  --bg-color: #f5f6fb;
  --second-bg-color: #fbfafc;
  --nav-bg-color: #febb0e;
  --nav-bg-color-hover: #ffdb7c;
  --nav-bg-color-active: #ffdb7c;
  --nav-bg-second-level-color: #fecc4a;
  --nav-bg-second-level-color-name: rgba(254, 204, 74, 0.9);
  --nav-bg-third-level-color: #ffde88;
  --nav-bg-third-level-color-tabs: rgba(254, 187, 14, 0.4);
  --side-nav-bg-color: rgba(254, 187, 14, 0, 25);

  --development-bg-color: #ffde88;
  --development-bg-section-color: #ffd057;
  --development-bg-section-color-active: #ffdb7c;

  --side-bg-color: #ffde88;
  --side-bg-color-active: #fde7ae;

  --modal-header-color: #f5f6fb;
  --modal-bg-color: #fff;
  --modal-bg-color-container: white;
  --modal-btn-color: #ffd057;

  --diagram-node-color: #ffd057;

  --checkbox-bg-color: white;
  --checkbox-border-color: black;

  --table-border-color: black;
  --table-bg-select-color: #fbf4e1;
  --table-icon-sorter-color: black;

  --button-color: #e5efff;
  --button-color-active: #0048b2;
  --button-normal-bg: #0048b2;
  --button-normal-hover-bg: hsl(#0048b2, 80%);
  --button-normal-color: #fff;
  --button-default-bg: #fff;
  --button-default-color: #0048b2;
  --button-second-bg: #e5efff;
  --button-second-color: #0048b2;
  --button-second-hover-bg: #d7e5fc;
  --button-danger-bg: #ea5455;
  --button-danger-color: #fff;
  --button-danger-hover-bg: lighten(#ea5455, 10%);

  --error-color: #ea5455;
}

:root[theme='dark'] {
  --color: rgb(237, 237, 237);
  --second-color: #5e6366;
  --third-color: #fff;
  --fourth-color: #000000de;
  --bg-color: rgba(85, 85, 85);
  --second-bg-color: #fbfafc;
  --nav-bg-color: rgb(73, 73, 73);
  --nav-bg-color-hover: rgb(94, 94, 94);
  --nav-bg-color-active: rgb(103, 129, 134);
  --nav-bg-second-level-color: rgb(73, 73, 73);
  --nav-bg-second-level-color-name: rgb(99, 99, 99);
  --nav-bg-third-level-color: rgb(228, 161, 161);
  --nav-bg-third-level-color-tabs: rgb(175, 150, 150);

  --development-bg-color: rgb(175, 150, 150);
  --development-bg-section-color: rgb(175, 150, 150);
  --development-bg-section-color-active: rgb(175, 150, 150);

  --side-bg-color: rgb(175, 150, 150);
  --side-bg-color-active: rgb(175, 150, 150);

  --modal-header-color: rgb(175, 150, 150);
  --modal-bg-color: rgb(175, 150, 150);
  --modal-bg-color-container: rgb(175, 150, 150);
  --modal-btn-color: rgb(175, 150, 150);

  --diagram-node-color: rgb(175, 150, 150);

  --checkbox-bg-color: white;
  --checkbox-border-color: rgb(175, 150, 150);

  --table-border-color: rgb(175, 150, 150);
  --table-bg-select-color: #fbf4e1;
  --table-icon-sorter-color: rgb(175, 150, 150);

  --error-color: #ea5455;
}

* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#b6b4ba, 0.5);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }
}

.icon {
  color: #5e6366;
  vertical-align: middle;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

html,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color) !important;
  color: var(--color);
}

.ant-radio-inner::after {
  width: 7px;
  height: 7px;
}

.ant-table-cell .ant-select {
  width: 100%;
  height: 100%;
}
.ant-table-cell .ant-select-selector {
  border: none !important;
}
.ant-table-cell .ant-input {
  border: none !important;
}

.ant-tooltip {
  z-index: 20000;
}

.ant-tooltip-inner {
  padding: 0.625em !important;
  background-color: #202124 !important;
  border-radius: 0.25em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0275em !important;
}

.ant-popover-inner,
.ant-popover-arrow-content {
  background-color: var(--nav-bg-color-active) !important;
  color: var(--color) !important;
}

// ant-checkboxes
#root,
.ant-modal-root {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5e6366;
    border-color: #5e6366;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5e6366;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #5e6366;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #5e6366;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }

  // .ant-checkbox-checked .ant-checkbox-inner::after {
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.44444 9.26222L0 4.81778L1.25333 3.56445L4.44444 6.74667L11.1911 0L12.4444 1.26222L4.44444 9.26222Z' fill='white'/%3E%3C/svg%3E%0A");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   border: none;
  //   transform: none;
  //   transition-property: transform;
  // }
}

.react-flow__arrowhead {
  polyline {
    stroke: black;
    fill: black;
  }
}

.modal {
  .top-content {
    display: flex;
    width: 100%;
    margin: 10px 0;
  }
  .bottom-content {
    display: flex;
    text-align: start;
    .grid-container {
      height: 250px;
      .dx-datagrid {
        border: 1px solid;
        border-bottom: none;
      }
    }
  }
  .field {
    display: flex;
    height: 30px;
    padding-left: 10px;
    align-items: center;
    > input {
      margin-left: 5px;
      height: 26px;
      border-width: thin;
      border: 1px solid #000000;
    }
  }
  .required::after {
    content: '*';
    color: #ff0000;
    padding-left: 3px;
    padding-bottom: 20px;
  }
  .btn-group-bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    > button {
      margin-left: 13px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

// dropdown-menu
.dropdown-menu {
  padding: 5px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  filter: drop-shadow(0px 5px 15px rgba(73, 95, 172, 0.2));
  border-radius: 4px;
  z-index: 1000;

  .ant-dropdown-menu-item {
    margin: 0 !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.25px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .ant-dropdown-menu-item-active:not(.ant-dropdown-menu-item-danger) {
    background: #e5efff !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-danger) {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .ant-dropdown-menu-item-danger {
    color: #d30000 !important;
  }

  .ant-dropdown-menu-item-active.ant-dropdown-menu-item-danger {
    background: #e5efff !important;
    color: #d30000 !important;
  }
}
