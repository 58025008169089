.calc-aggregate {
  position: relative;
  padding: 30px 24px;

  &__info {
    display: grid;
    max-width: 754px;
    grid-template-columns: 2fr 1fr;
    gap: 24px 48px;
  }

  &__description {
    grid-column: span 2;
  }

  &__grouping-elements {
    margin-top: 30px;
    max-width: 754px;
  }

  &__time {
    margin-top: 30px;
    display: grid;
    gap: 24px;

    &-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 40px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-group {
      display: flex;
      align-items: center;
      flex: 1;

      > *:first-child {
        .input-ext.ant-input-lg {
          border-radius: 4px 0 0 4px !important;
          line-height: 1.429 !important;
        }
      }

      > *:last-child {
        .ant-select-selector {
          height: 48px !important;
          border-radius: 0 4px 4px 0 !important;
        }
        .input-ext.ant-input-lg {
          border-radius: 0 4px 4px 0 !important;
        }
      }
    }

    &-label {
      margin-right: 20px;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.25px;
      color: #5e6366;
    }

    &-field {
      max-width: 272px;
      flex: 1;

      .ant-input-affix-wrapper {
        height: 48px;
        border-radius: 4px;
      }
    }
  }

  &__table {
    margin-top: 30px;
  }

  &__input-with-button {
    margin: -12px 0;

    .ant-input-affix-wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
