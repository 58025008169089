.administration-page {
  flex: 1;
  // display: flex;
  flex-direction: row;
  .content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .header-title {
      display: flex;
      align-items: center;
      background-color: var(--nav-bg-second-level-color);
      height: 50px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      padding: 20px;
      background-color: var(--nav-bg-third-level-color);
    }
  }
}
