.choose-attributes {
  padding: 30px 24px 24px 24px;

  &__search {
    width: 250px;
  }
  &__table {
    margin-top: 30px;
    height: 440px;
  }
}
