%button {
  padding: 12px;
  display: flex;
  min-width: 128px;
  align-items: center;
  justify-content: center;
  background: var(--button-normal-bg);
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: var(--button-normal-color);
  text-transform: uppercase;
  transition: 0.3s ease-in;
  transition-property: background-color, opacity, color;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  .icon:not(:last-child) {
    margin: 0 8px 0 0;
  }
}

%new-button {
  cursor: pointer;
  background: var(--button-color);
  color: var(--button-color-active);
  box-sizing: border-box;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
}

%square {
  padding: 16px;
  width: min-content;
  background-color: rgba(#b6b4ba, 0.1);
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: rgba(#b6b4ba, 0.3);
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
}

.button {
  &_default {
    @extend %button;
    padding: 11px;
    background-color: var(--button-default-bg);
    color: var(--button-default-color);
    border: 1px solid var(--button-default-color);
  }

  &_normal {
    @extend %button;

    &:not(:disabled):hover {
      background: var(--button-normal-hover-bg);
    }
  }

  &_border {
    @extend %button;
    padding: 6px 0;
    border: 1px solid #000000;
  }

  &_transparent {
    // @extend %button;
    padding: 0;
    background-color: transparent;
    border: none;

    &:disabled {
      cursor: default;
      opacity: 0.4;
    }
  }

  &_small {
    padding: 4px 18px;
  }

  &_extra-small {
    padding: 0 20px;
  }

  &_icon {
    margin: 0;
    padding: 0;
    display: flex;
    width: 38px;
    min-width: 38px;
    height: 38px;
    min-height: 38px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    cursor: pointer;

    &:not(:disabled):hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:disabled {
      opacity: 0.4;
      cursor: auto;
    }
  }

  &_square {
    @extend %square;
  }

  &_square-s {
    @extend %square;
    padding: 12px;
  }

  &_square-ss {
    @extend %square;
    padding: 0;
  }

  &_square-s-b {
    @extend %square;
    padding: 11px;
    background: none;
    border: 1px solid #eaeaed;
    transition: all 0.3s;

    &:not(:disabled):hover {
      background: none;
      border-color: #1f64bf;
    }

    &:not(:disabled):focus {
      background: none;
      border-color: #1f64bf;
      box-shadow: 0 0 0 2px #0048b233;
    }
  }

  &--second {
    background: var(--button-second-bg);
    color: var(--button-second-color);

    &:not(:disabled):hover {
      background: var(--button-second-hover-bg);
    }
  }

  &--danger {
    background: var(--button-danger-bg);
    color: var(--button-danger-color);

    &:not(:disabled):hover {
      background: var(--button-danger-hover-bg);
    }
  }

  &--min-width {
    min-width: initial;
  }

  &--box-shadow {
    box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.16),
      0px 2px 4px rgba(54, 123, 245, 0.24);
  }

  &--hover-box-shadow {
    transition: all 0.3s ease;

    &:not(:disabled):hover {
      box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.16),
        0px 2px 4px rgba(54, 123, 245, 0.24);
    }
  }

  &--hover-default {
    transition: all 0.3s ease;

    &:not(:disabled):hover {
      background-color: var(--button-default-color);
      color: var(--button-default-bg);
    }
  }

  &--transparent {
    background-color: transparent;
  }

  &--usual-text-style {
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }

  &--default-border {
    border-color: #ebeaed;
  }
}

.new-button {
  &_normal {
    @extend %new-button;
  }
  &_border {
    @extend %new-button;
    padding: 6px 0;

    border: 1px solid #000000;
  }
  &_transparent {
    @extend %new-button;
    background-color: transparent;
  }
  &_small {
    padding: 4px 18px;
  }
  &_extra-small {
    padding: 0 8px;
  }
  &_active {
    @extend %new-button;
    background-color: var(--button-color-active);
    color: var(--third-color);
  }
}
