.decision-table {
  padding: 20px 24px 24px;

  .decision-table {
    &__top-content {
      margin-bottom: 24px;
      display: flex;
    }
    &__field {
      flex-basis: 100%;
      &--title {
        flex-basis: 43%;
      }
      &--code {
        flex-basis: 27%;
        margin-left: 47px;
      }
      &--description {
        flex-basis: 74.5%;
      }
    }
  }

  &__radios {
    margin-top: 6px;
    .ant-radio-button-wrapper {
      color: var(--second-color);
      &:hover {
        border-color: var(--button-color-active);
      }
    }
  }
  &__radio {
    font-size: 14px;
    height: 36px;
    padding: 0;
    border-radius: 4px !important;
    span {
      display: block;
      text-align: center;
      padding-top: 2px;
    }
    &--decision-table {
      width: 145px;
    }
    &--cross-table {
      width: 132px;
      border-left-width: 1px !important;
      &::before {
        content: none !important;
      }
    }
  }
  &__tables {
    margin-top: 30px;

    .table-attr-row {
      display: flex;
      justify-content: space-between;
      &_value {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_tooltip {
        cursor: pointer;
      }
    }
  }
  &__cross-table {
    .ant-table-cell-fix-left {
      background-color: #fafafa !important;
    }
    .ant-table {
      height: 204px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child,
    .ant-table-container table > thead > tr:first-child th:last-child {
      background-color: white !important;
      padding: 0;
      .ant-input-affix-wrapper {
        justify-content: center;
      }
      input {
        width: auto;
      }
    }
  }
  &__decision-table {
    .ant-table {
      height: 223px;
    }
  }
  &__table-edit-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__input,
  &__input-with-button--title {
    min-width: 202px;
  }
}
