.balalaika__checkbox {
  display: flex;
  flex-direction: column;

  &-top {
    margin-bottom: 8px;
    display: inline-block;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
    position: relative;
  }

  &-required {
    position: absolute;
    left: 100%;
    bottom: 10px;
    margin-left: 5px;
    color: #ea5455;
  }

  .ant-checkbox-wrapper {
    height: 48px;

    .ant-checkbox {
      margin: auto 0;
    }
  }
}