.add-new-element {
  padding: 30px 24px;

  &__table {
    &-checkbox-cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-edit-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
