%tab-links {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: inherit;
  border: none;
  outline: none;
  text-align: center;
  font-weight: 500;
  margin: 0 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.tabs {
  flex: 1;
  overflow: hidden;
  display: flex;
  margin: 0 10px;
  .tab-links {
    position: relative;
    @extend %tab-links;
    &.active {
      color: #d6792d;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 3px solid #d6792d;
        border-top-left-radius: 90px 90px;
        border-top-right-radius: 90px 90px;
      }
    }
    &.disabled {
      background: #eee;
      color: #ccc;
      pointer-events: none;
    }
  }

  .level {
    &-1 {
      margin: 5px;
      .tab-links__title {
        display: flex;
        > p {
          font-size: 24px;
          margin: 9px;
        }
      }
    }
    &-2 {
      padding: 4px;
      .tab-links__title {
        display: flex;
        > p {
          font-size: 18px;
          margin: 7px;
        }
      }
    }
    &-3 {
      height: 54px;
      .tab-links__title {
        padding: 0 20px;
        > p {
          margin: 15px 0;
        }
      }
    }
  }
}

.tabs-with-container {
  margin: -17px -20px;
  .tab-links {
    flex: 1;
    margin: 0;
    &.active {
      color: var(--color);
      background-color: white;
      &::after {
        border: none;
      }
    }
  }
}

.custom-code {
  > div:nth-child(2) {
    width: 300px;
  }
}
