@import '../../mixins.scss';

.connections {
  @include card-styles();

  &__content {
    overflow-y: auto;
  }

  .connections__cell_blu {
    color: #0048B2
  }
  .connections__cell_green {
    color: #7db700
  }
  .connections__cell_yellow {
    color: #FFCC00
  }
  .connections__cell_red {
    color: #ff0000
  }
}

.connections__table {
  tr.ant-table-row.active-row > td,
  tr.ant-table-row.active-row:hover > td {
    background: lighten(#0e3c99, 60%);
  }

  .ant-table-thead {
    > tr > th {
      background: #fafafa;
    }

    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      content: none;
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 2px;
      transform: translate(0, 0);
    }

    .ant-checkbox-inner::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7px;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: initial;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-column-sorter {
    margin-left: 10px;
  }

  .ant-table:not(.ant-table-small):not(.ant-table-middle) {
    .ant-table-selection-col {
      min-width: 56px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f7f9fc;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td,
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #e5efff;
  }
}