.select-ext {
  &__container {
    position: relative;

    &--error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #ffa39e;
    }

    &--error .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #f5222d;
    }

    &--error
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: #f5222d;
      box-shadow: 0 0 0 2px rgba(#f5222d, 0.2) !important;
    }
  }
  &__top {
    margin-bottom: 8px;
    display: inline-block;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #5e6366;
    position: relative;
  }
  &__required {
    position: absolute;
    left: 100%;
    bottom: 10px;
    margin-left: 5px;
    color: #ea5455;
  }

  &__icon {
    width: 12px;
    height: 6px;
    color: rgba(0, 0, 0, 0.54);
  }
}
.select-ext.ant-select {
  width: 100%;
}
.ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #c6cacc;
  height: 36px !important;
  align-items: center;
}
.ant-select-lg .ant-select-selector {
  height: 48px !important;
}
.ant-select-selection-item {
  color: var(--color);
  margin: auto;
}
.select-ext.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-dropdown {
  z-index: 20000;
}

.select-ext .ant-select-selection-item {
  text-align: left;
}

.ant-select-lg {
  font-size: 14px;
}
