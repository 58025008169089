@import '../../mixins.scss';

.environments {
  position: relative;
  margin: 16px 0;
  @include card-styles();

  &__table {
    @extend %table;
  }
}
