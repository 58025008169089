.dropdown-menu {
  padding: 5px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  filter: drop-shadow(0px 5px 15px rgba(73, 95, 172, 0.2));
  border-radius: 4px;
  z-index: 1000;
}

.dropdown-menu__item {
  margin: 0 !important;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);

  &.ant-menu-item-active:not(.ant-menu-item-danger) {
    background: #e5efff;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &.ant-menu-item-selected:not(.ant-menu-item-danger) {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &.ant-menu-item-danger {
    color: #d30000 !important;
  }

  &.ant-menu-item-active.ant-menu-item-danger {
    background: #e5efff;
    color: #d30000 !important;
  }
}
