@import '../../../mixins.scss';

.card {
  @include card-styles();
  &__top {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: none;
    color: var(--second-color);
    padding: 24px;

    > svg {
      margin-right: 8px;
    }
  }
  &__additional-header-element {
    margin-left: auto;
  }
}
